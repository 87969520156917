import { render, staticRenderFns } from "./AuthByPopup.vue?vue&type=template&id=59c3245b&"
import script from "./AuthByPopup.vue?vue&type=script&lang=js&"
export * from "./AuthByPopup.vue?vue&type=script&lang=js&"
import style0 from "./AuthByPopup.vue?vue&type=style&index=0&id=59c3245b&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../auth-app-ui/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports