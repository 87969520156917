import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import authenticator from '@common-ui/authenticator'
import '@common-ui/common/provide-styles.css'
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false
Vue.use(authenticator, { store });

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  mounted() {
    console.log('mounted')
    // authstore.dispatch('tryFromSessionStorage')
  },
  created() {
    console.log('created')
    //Only set sessionStorage if it is not iFrame window for Auth
    let isiFrameWindowAuth = window.location.hash.includes("#KennectAuth")
    if (sessionStorage.hasOwnProperty('auth') && sessionStorage.getItem('auth') !== '' && !isiFrameWindowAuth) {
      this.$store.dispatch('authStore/setAuthFromSessionStorage');
    }
  }
}).$mount('#app')
