<template></template>

<script>

import attemptAuthByOpener from "../api/attemptAuthenticationByOpener";
import authByPopUpUtils from "../api/attemptAuthByPopUpUtils";
import authUtils from "../api/authUtils"

export default {
  props: ["opener","type"],

  data: () => ({
    authSourceOrigin: null,
  }),

  mounted() {
    console.log('this.type ::::::::::>> ', this.type);
    this.authSourceOrigin = this.opener;
    sessionStorage.setItem("opener", this.authSourceOrigin)
    console.log(`[authSourceOrigin]: `, this.authSourceOrigin, authByPopUpUtils);
    // Check if authByOpener is attempting from valid origin or from White list IPs
    if(this.authSourceOrigin === authByPopUpUtils.KX_UI || this.authSourceOrigin === authByPopUpUtils.KNX_UI || this.authSourceOrigin === authByPopUpUtils.DSI_UI || this.authSourceOrigin === authByPopUpUtils.BI_UI || this.authSourceOrigin === authByPopUpUtils.DW_UI || this.authSourceOrigin === authByPopUpUtils.INC_UI){
        attemptAuthByOpener(this.type, window, this.authSourceOrigin, (err, payload) => {
        console.log("auth result: ", err ? err : payload);

        if (err) {
          console.log("FAILED TO AUTHENTICATE. ", err);
          console.log(`%c  FAILED TO AUTHENTICATE. ERROR: ${err}`, "color:red; font-weight:bold;font-size:20px")
          return;
        }

        try {

          if(payload && payload.hasOwnProperty('accessToken')){
            // console.log('payload :>> ', payload);
            // console.log('this.$store :>> ', this.$store);
            if(!!payload.specialLoginFromOtherKennectApp){
              this.$store.commit("authStore/setSpecialLoginPayload", payload.specialLoginFromOtherKennectApp.payload)
            }
            this.$emit("onAuthSuccess", payload);
            // handles logout request from parent window
            authUtils.setLogoutReqFromParent(this.$store)
          }else{
            console.log(`%c INVALID AUTH RESPONSE`, "color:red; font-weight:bold;font-size:20px")
            return
          }  

        } catch (error) {
          console.log('error :', error);
          console.log("ERROR AFTER CHILD WINDOW LOGOUT")
        }
      });
    }else{
      console.log("%c  INVALID AUTH SOURCE ATTEMPT", "color:red; font-weight:bold;font-size:20px")
      return
    }
  }
}
</script>

<style>
</style>