<template>
  <v-app>
    <!--
      isPasswordReset will be true if Password reset is initiated by type 20 or 24 user
      if isPasswordReset == true then on clicking on link which was sent in email, it directly navigated to the password reset form
      If isPasswordReset == false then it will show noraml login form
    -->
    <!-- <template v-if="!isPasswordReset"> -->
    <template>
      <!-- Logged-out K1 -->
      <kx-login-now v-if="!isLoggedIn" @login="login" />
      <v-app-bar v-if="isLoggedIn" app color="#01595a" dark>
        <div class="d-flex align-center">
          <v-avatar size="70">
            <v-img
              alt="L"
              style="border-radius:0%"
              class="shrink mr-2"
              contain
              src="@common-ui/common/images/final_logo_o2576_1.svg"
              transition="scale-transition"
              width="40"
            />
          </v-avatar>
          Kennect Hub
        </div>

        <v-spacer></v-spacer>

        <v-btn v-if="userType === 20" text dark @click="dialogClientAccess=true">LRT</v-btn>
        <!-- <v-btn flat text>Report</v-btn> -->
        <kx-mini-profile
          :isLogoutLoading="isLogoutLoading"
          :keepOpen="false"
          :accessToken="accessToken"
          :mortal="mortal"
          :refreshToken="refreshToken"
          v-on:logout="logout"
        />
      </v-app-bar>
      <v-content v-if="isLoggedIn">
        <kx-portals-list />

        <v-dialog
          v-model="dialogClientAccess"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <!-- <template v-slot:activator="{ on }"> </template> -->
          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="dialogClientAccess = false">
                <v-icon>close</v-icon>
              </v-btn>
              <v-toolbar-title>One short URL</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-container grid-list-md>
                <v-layout row wrap>
                  <v-flex xs12 sm5 md5>
                    <v-text-field
                      v-model="username"
                      label="Username"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm5 md5>
                    <v-text-field
                      v-model="password"
                      prepend-inner-icon="mdi-lock"
                      label="Passsword"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPassword ? 'text' : 'password'"
                      @click:append="showPassword = !showPassword"
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm2 md2>
                    <v-btn
                      class="mt-3"
                      dark
                      color="primary"
                      @click="generateToken"
                      >Generate Token</v-btn
                    >
                  </v-flex>
                </v-layout>
                <v-flex xs12>
                  <v-textarea
                    style="margin-top: 8%"
                    v-model="tokenOutput"
                    outlined
                    shaped
                    label="Generated Token Output"
                    auto-grow
                  ></v-textarea>
                </v-flex>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-content>
    </template>
    <!-- Snack Bar -->
    <v-snackbar v-model="showSnackbar" top="top">
      {{ snackBarText }}
    </v-snackbar>
  </v-app>
</template>

<script>
import { createNamespacedHelpers as cnh } from "vuex";
const { mapGetters: mapAuthStoreGetters, mapActions: mapAuthStoreActions } =
  cnh("authStore");
import kxBrowserUtils from "@common-ui/authenticator/api/browserUtils";
export default {
  name: "App",
  components: {},
  data: () => ({
    showPassword: false,
    tokenOutput: "",
    // loggedIn: false,
    dialogClientAccess: false,
    isLogoutLoading: false,
    mortal: false,
    accessToken: false,
    refreshToken: false,
    id: false,
    // isPasswordReset: false, // will be true if Password reset is initiated by type 20 or 24 user
    username: "",
    password: "",
    snackBarText: "",
    showSnackbar: false,
  }),
  computed: {
    ...mapAuthStoreGetters(["isLoggedIn", "userType"]),
  },
  methods: {
    ...mapAuthStoreActions({
      authStoreLogout: "logout",
      generateClientSLRT: "generateClientSLRT",
    }),
    login({ accessToken, refreshToken, mortal }) {
      this.id = mortal.id + "~" + mortal.username;
      this.mortal = mortal;
      this.accessToken = accessToken;
      this.refreshToken = refreshToken;
      // To store all child windows reference which are spawned from parent/root window
      window.allChilds = [];
    },
    async logout() {
      try {
        this.isLogoutLoading = true;
        await this.authStoreLogout({
          selfLogoutInitiated: true,
        });
        this.isLogoutLoading = false;
      } catch (error) {
        console.log("error", error);
        this.isLogoutLoading = false;
      }
    },
    async generateToken() {
      try {
        this.generateClientSLRT({
          username: this.username,
          password: this.password,
        })
          .then((clientSLRTResp) => {
            this.snackBarText = `Client SLRT Generated Successfully`;
            this.showSnackbar = true;
            this.tokenOutput = clientSLRTResp.slrt;
          })
          .catch((err) => {
            console.log("err :>> ", err);
            this.snackBarText = `Failed to Generate Client SLRT.\n${err.message}`;
            this.showSnackbar = true;
          });
      } catch (error) {
        console.log("error :>> ", error);
        this.snackBarText = `Failed to Generate Client SLRT.\n${error.message}`;
        this.showSnackbar = true;
      }
    },
  },
  // created() {
  //   if (window.location.hash !== "") {
  //     let { username } = kxBrowserUtils.extractHash(window);
  //     if (username && username !== "") {
  //       this.isPasswordReset = true;
  //     }
  //   }
  // },
};
</script>
