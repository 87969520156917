module.exports = () => {
    console.log('[kx-sdk] Building with buildMode [VUE_APP_BUILD_MODE]', process.env.VUE_APP_BUILD_MODE);
    console.log('[kx-sdk] Building with buildMode [NODE_ENV]', process.env.NODE_ENV);
    const buildMode = (process.env.VUE_APP_BUILD_MODE && process.env.VUE_APP_BUILD_MODE === 'staging')
        ? 'stg'
        : (process.env.NODE_ENV === 'dev') ? "dev" : (process.env.NODE_ENV === 'dev_alpha') ? "dev_alpha" : (process.env.NODE_ENV === 'production')
            ? 'prod'
            : 'local';

    let buildInfo = {
        buildMode,
        // l2d: process.env.NODE_ENV == 'development',
        // repo: process.env.VUE_APP_REPO_NAME || 'unknown',
        stg: (buildMode == 'stg'),
        prod: (buildMode == 'prod'),
        dev: (buildMode == 'dev'),
        dev_alpha: (buildMode == 'dev_alpha'),
        local: (buildMode == 'local'),
    };
    console.log('[kx-sdk] Building with buildInfo: ', buildInfo)
    return buildInfo;
}