module.exports = {
    su: {
        title: "SU",
        description: "Super User",
        color: "blue darken-1",
        uriKey: 'BX_UI'
    },
    bx: {
        title: "BX",
        description: "Business Coordination",
        color: "blue darken-1",
        uriKey: 'BX_UI'
    },
    dx: {
        title: "DW",
        description: "Data Warehouse",
        color: "yellow darken-1",
        uriKey: 'DW_UI'
    },
    dsi: {
        title: "DSI",
        description: "Business Excellence",
        color: "green darken-1",
        uriKey: 'DSI_UI'
    },
    incentive: {
        title: "INC",
        description: "Incentive Excellence",
        color: "cyan darken-1",
        uriKey: 'INC_UI'
    },
    incentive: {
        title: "INC",
        description: "Incentive Excellence",
        color: "cyan darken-1",
        uriKey: 'INC_UI'
    },
    kenx: {
        title: "KNOW",
        description: "Employee Excellence",
        color: "orange darken-1",
        uriKey: 'KNX_UI'
    },
    // bi: {
    //     title: "BI",
    //     description: "BI Reports",
    //     color: "orange darken-1",
    //     uriKey: 'BI_UI'
    // },
};